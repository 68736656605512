body{
    font-family: 'Inter';
}

@font-face {
    font-family: 'Arial-Bold';
    src: local('Arial-Bold'),
         url('./fonts/Arial-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;  
}

@font-face {
    font-family: 'Arial';
    src: local('Arial'),
         url('./fonts/Arial.woff') format('woff'),
}

@font-face {
    font-family: 'MeshedDisplay-Bold';
    src: local('MeshedDisplay-Bold'),
         url('./fonts/MeshedDisplay-Bold.woff') format('woff'),
}

@font-face {
    font-family: 'Barracuda-Bold';
    src: local('Barracuda-Bold'),
         url('./fonts/Barracuda-Bold.woff') format('woff'),
}

@font-face {
    font-family: 'Barracuda-regular';
    src: local('Barracuda-regular'),
         url('./fonts/Barracuda-regular.woff') format('woff'),
}

@font-face {
    font-family: 'Betelgeuse';
    src: local('Betelgeuse'),
         url('./fonts/Betelgeuse.woff') format('woff'),
}

@font-face {
    font-family: 'Bookman';
    src: local('Bookman'),
         url('./fonts/Bookman.woff') format('woff'),
}

@font-face {
    font-family: 'Cactron-Bold';
    src: local('Cactron-Bold'),
         url('./fonts/Cactron-Bold.woff') format('woff'),
}

@font-face {
    font-family: 'Cactron-Regular';
    src: local('Cactron-Regular'),
         url('./fonts/Cactron-Regular.woff') format('woff'),
}

@font-face {
    font-family: 'Comic-Sans-MS';
    src: local('Comic-Sans-MS'),
         url('./fonts/Comic-Sans-MS.woff') format('woff'),
}

@font-face {
    font-family: 'Dukascf-Bold';
    src: local('Dukascf-Bold'),
         url('./fonts/Dukascf-Bold.woff') format('woff'),
}

@font-face {
    font-family: 'Dukascf-Regular';
    src: local('Dukascf-Regular'),
         url('./fonts/Dukascf-Regular.woff') format('woff'),
}

@font-face {
    font-family: 'EmberlyBold';
    src: local('EmberlyBold'),
         url('./fonts/EmberlyBold.woff') format('woff'),
}

@font-face {
    font-family: 'EmberlyRegular';
    src: local('EmberlyRegular'),
         url('./fonts/EmberlyRegular.woff') format('woff'),
}

@font-face {
    font-family: 'epigrafica-bold';
    src: local('epigrafica-bold'),
         url('./fonts/epigrafica-bold.woff') format('woff'),
}

@font-face {
    font-family: 'epigrafica-regular';
    src: local('epigrafica-regular'),
         url('./fonts/epigrafica-regular.woff') format('woff'),
}

@font-face {
    font-family: 'Garamond';
    src: local('Garamond'),
         url('./fonts/Garamond.woff') format('woff'),
}

@font-face {
    font-family: 'Georgia';
    src: local('Georgia'),
         url('./fonts/Georgia.woff') format('woff'),
}

@font-face {
    font-family: 'Grenze-Bold';
    src: local('Grenze-Bold'),
         url('./fonts/Grenze-Bold.woff') format('woff'),
}

@font-face {
    font-family: 'Grenze-Regular';
    src: local('Grenze-Regular'),
         url('./fonts/Grenze-Regular.woff') format('woff'),
}

@font-face {
    font-family: 'Horizon-Regular';
    src: local('Horizon-Regular'),
         url('./fonts/Horizon-Regular.woff') format('woff'),
}

@font-face {
    font-family: 'impact';
    src: local('impact'),
         url('./fonts/impact.woff') format('woff'),
}

@font-face {
    font-family: 'Karantina-Bold';
    src: local('Karantina-Bold'),
         url('./fonts/Karantina-Bold.woff') format('woff'),
}

@font-face {
    font-family: 'Karantina-Regular';
    src: local('Karantina-Regular'),
         url('./fonts/Karantina-Regular.woff') format('woff'),
}

@font-face {
    font-family: 'KubanPro-Bold';
    src: local('KubanPro-Bold'),
         url('./fonts/KubanPro-Bold.woff') format('woff'),
}

@font-face {
    font-family: 'KubanPro-Regular';
    src: local('KubanPro-Regular'),
         url('./fonts/KubanPro-Regular.woff') format('woff'),
}

@font-face {
    font-family: 'Kufam-Bold';
    src: local('Kufam-Bold'),
         url('./fonts/Kufam-Bold.woff') format('woff'),
}

@font-face {
    font-family: 'Kufam-Regular';
    src: local('Kufam-Regular'),
         url('./fonts/Kufam-Regular.woff') format('woff'),
}

@font-face {
    font-family: 'MangoGrotesque-Bold';
    src: local('MangoGrotesque-Bold'),
         url('./fonts/MangoGrotesque-Bold.woff') format('woff'),
}

@font-face {
    font-family: 'MangoGrotesque-Regular';
    src: local('MangoGrotesque-Regular'),
         url('./fonts/MangoGrotesque-Regular.woff') format('woff'),
}

@font-face {
    font-family: 'MeshedDisplay-Regular';
    src: local('MeshedDisplay-Regular'),
         url('./fonts/MeshedDisplay-Regular.woff') format('woff'),
}

@font-face {
    font-family: 'Nymeria-Bold';
    src: local('Nymeria-Bold'),
         url('./fonts/Nymeria-Bold.woff') format('woff'),
}

@font-face {
    font-family: 'Nymeria-Regular';
    src: local('Nymeria-Regular'),
         url('./fonts/Nymeria-Regular.woff') format('woff'),
}

@font-face {
    font-family: 'Palatino';
    src: local('Palatino'),
         url('./fonts/Palatino.woff') format('woff'),
}

@font-face {
    font-family: 'Palatino';
    src: local('Palatino'),
         url('./fonts/Palatino.woff') format('woff'),
}

@font-face {
    font-family: 'Piazzolla-Bold';
    src: local('Piazzolla-Bold'),
         url('./fonts/Piazzolla-Bold.woff') format('woff'),
}

@font-face {
    font-family: 'Piazzolla-Regular';
    src: local('Piazzolla-Regular'),
         url('./fonts/Piazzolla-Regular.woff') format('woff'),
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: local('Poppins-SemiBold'),
         url('./fonts/Poppins-SemiBold.woff') format('woff'),
}

@font-face {
    font-family: 'RadioCanada-Bold';
    src: local('RadioCanada-Bold'),
         url('./fonts/RadioCanada-Bold.woff') format('woff'),
}

@font-face {
    font-family: 'RadioCanada-Regular';
    src: local('RadioCanada-Regular'),
         url('./fonts/RadioCanada-Regular.woff') format('woff'),
}

@font-face {
    font-family: 'Rebeqa-Bold';
    src: local('Rebeqa-Bold'),
         url('./fonts/Rebeqa-Bold.woff') format('woff'),
}

@font-face {
    font-family: 'Rebeqa-Regular';
    src: local('Rebeqa-Regular'),
         url('./fonts/Rebeqa-Regular.woff') format('woff'),
}

@font-face {
    font-family: 'RogerexBold';
    src: local('RogerexBold'),
         url('./fonts/RogerexBold.woff') format('woff'),
}

@font-face {
    font-family: 'RogerexRegular';
    src: local('RogerexRegular'),
         url('./fonts/RogerexRegular.woff') format('woff'),
}

@font-face {
    font-family: 'Rozina-Bold';
    src: local('Rozina-Bold'),
         url('./fonts/Rozina-Bold.woff') format('woff'),
}

@font-face {
    font-family: 'Rozina';
    src: local('Rozina'),
         url('./fonts/Rozina.woff') format('woff'),
}

@font-face {
    font-family: 'sans-serif-smallcaps';
    src: local('sans-serif-smallcaps'),
         url('./fonts/sans-serif-smallcaps.woff') format('woff'),
}

@font-face {
    font-family: 'sans-serif-thin';
    src: local('sans-serif-thin'),
         url('./fonts/sans-serif-thin.woff') format('woff'),
}

@font-face {
    font-family: 'serif-monospace';
    src: local('serif-monospace'),
         url('./fonts/serif-monospace.woff') format('woff'),
}

@font-face {
    font-family: 'serif';
    src: local('serif'),
         url('./fonts/serif.woff') format('woff'),
}

@font-face {
    font-family: 'times-new-roman';
    src: local('times-new-roman'),
         url('./fonts/times-new-roman.woff') format('woff'),
}

@font-face {
    font-family: 'trebuc-ms';
    src: local('trebuc-ms'),
         url('./fonts/trebuc-ms.woff') format('woff'),
}

@font-face {
    font-family: 'Unbounded-Bold';
    src: local('Unbounded-Bold'),
         url('./fonts/Unbounded-Bold.woff') format('woff'),
}

@font-face {
    font-family: 'Unbounded-Regular';
    src: local('Unbounded-Regular'),
         url('./fonts/Unbounded-Regular.woff') format('woff'),
}

@font-face {
    font-family: 'utkal';
    src: local('utkal'),
         url('./fonts/utkal.woff') format('woff'),
}

@font-face {
    font-family: 'verdana';
    src: local('verdana'),
         url('./fonts/verdana.woff') format('woff'),
}

@font-face {
    font-family: 'Bainsley-Bold';
    src: local('Bainsley-Bold'),
         url('./fonts/Bainsley-Bold.woff') format('woff'),
}

@font-face {
    font-family: 'Bainsley-Regular';
    src: local('Bainsley-Regular'),
         url('./fonts/Bainsley-Regular.woff') format('woff'),
}

@font-face {
    font-family: 'Cormorant-Bold';
    src: local('Cormorant-Bold'),
         url('./fonts/Cormorant-Bold.woff') format('woff'),
}

@font-face {
    font-family: 'Cormorant-Regular';
    src: local('Cormorant-Regular'),
         url('./fonts/Cormorant-Regular.woff') format('woff'),
}